import React, { useState } from "react";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import api from "../../services/api";

import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
// import clsx from "clsx";
import InputLabel from "@material-ui/core/InputLabel";

// import logoSistema from '../../../assets/img/logo-empresa.png';
import logoSistema from "../../assets/img/logo-empresa.png";

// import CircularProgress from "@material-ui/core/CircularProgress";

import {
  setNomeUsuario,
  login,
  setIdUsuario,
  setTipoUsuario,
} from "../../services/auth";
import Footer from "../../components/footer-admin";

function Copyright() {
  return (
    <Footer />
    // <Typography variant="body2" color="textSecondary" align="center">
    //   {'Copyright © '}
    //   <Link color="inherit" href="https://bioinformaticalabs.com/">
    //     BioInformaticaLabs.com
    //   </Link>{' '}
    //   {new Date().getFullYear()}
    //   {'.'}
    // </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    // alert('Autenticar: '+email)

    // setLoading(true);

    await api.post("/api/usuarios/login", { email, senha }).then((res) => {
      if (res.status === 200) {
        //

        if (res.data.status === 1) {
          // armazenar no local storage
          // alert('Autenticação com Sucesso com status 1');
          login(res.data.token);
          setIdUsuario(res.data.id_client);
          setNomeUsuario(res.data.user_name);
          setTipoUsuario(res.data.user_type);

          console.log("Estou mudando de pagina--------------------------");
          window.location.href = "/admin";
        } else if (res.data.status === 2) {
          // alert('Autenticação com Sucesso com status 2');
          alert("Atenção: ", res.data.error);
        }
        // setLoading(false);
      } else {
        alert("Erro de autenticação no servidor");
        // setLoading(false);
      }
    });
  }

  function loadSubmit() {
    // setLoading(true);
    setTimeout(() => handleSubmit(), 20);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Link href="https://smartseeds.com.br" target="_blank">
          <img
            style={{ width: 300, height: 100 }}
            src={logoSistema}
            alt="Logo Sistema"
          />
        </Link>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Digite seu usuário"
          name="email"
          autoComplete="email"
          // autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* 
          <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined"> */}

        <FormControl
          variant="outlined"
          style={{ width: "100%", marginTop: 10 }}
        >
          <InputLabel htmlFor="campoSenha">Digite sua senha</InputLabel>
          <OutlinedInput
            id="campoSenha"
            type={showPassword ? "text" : "password"}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={90}
          />
        </FormControl>

        <Link href="">
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={loadSubmit}
            style={{
              width: "300px",
              backgroundColor: "#18C7CD",
              color: "#fff",
              marginTop: "25px",
              textDecoration: "none",
            }}
          >
            Entrar
          </Button>
        </Link>

        {/* <Link href="https://novousuario.smartseeds.com.br" target="_blank">
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={loadSubmit}
            style={{
              width: "300px",
              backgroundColor: "#18C7CD",
              color: "#fff",
              marginTop: "-5px",
              textDecoration: "none",
              opacity: 0.5,
            }}
          >
            Novo Usuário
          </Button>
        </Link> */}

        <Grid container></Grid>
        {/* </form> */}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
